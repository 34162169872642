import axios from "axios";
import store from "@/store";
const qs = require('qs')

// export const baseURL = 'https://60.30.57.78:8101/'
export const baseURL = 'http://60.30.57.78:8100/'
const instance = axios.create({
  baseURL,
  timeout: 5000
})

export default (url, parm) => {
  let obj
  if (typeof parm === 'object') {
    obj = parm
  } else {
    obj = { hours: parm }
  }
  return instance({
    url, method: 'post', data: qs.stringify({ ...obj, token: store.getters.getToken })
  })
}