<template>
  <div :id="name" :ref="refName">
    <slot v-if="ready"></slot>
  </div>
</template>
<script>
export default {
  name: 'BaseContainer',
  props: {
    // 区分组件
    name: String,
    options: Object,
    refName: String,
  },
  data() {
    return {
      width: 0, // 设计图宽度
      height: 0, // 设计图高度
      originalWidth: 0, // 设备屏幕物理宽度
      originalHeight: 0, // 设备屏幕物理高度
      ready: false,
      context: null,
      dom: null,
      observer: null,
    };
  },
  mounted() {
    this.ready = false;
    this.context = this.$refs[this.refName];
    this.initSize().then(() => {
      this.updateSize();
      this.updateScale();
      window.addEventListener('resize', this.onResize);
      this.initMutationObserver();
      this.ready = true;
    });
  },
  methods: {
    // 初始化尺寸
    async initSize() {
      await this.$nextTick();
      this.dom = this.$refs[this.refName];
      if (this.options && this.options.width && this.options.height) {
        this.width = this.options.width;
        this.height = this.options.height;
      } else {
        this.width = this.dom.clientWidth;
        this.height = this.dom.clientHeight;
      }
      if (!this.originalWidth || !this.originalHeight) {
        this.originalWidth = window.screen.width;
        this.originalHeight = window.screen.height;
      }
    },
    // 更新尺寸
    updateSize() {
      if (this.width && this.height) {
        this.dom.style.width = `${this.width}px`;
        this.dom.style.height = `${this.height}px`;
      } else {
        this.dom.style.width = `${this.originalWidth}px`;
        this.dom.style.height = `${this.originalHeight}px`;
      }
    },
    // 更新宽高比
    updateScale() {
      const currentWidth = document.body.clientWidth;
      const currentHeight = document.body.clientHeight;

      const realWidth = this.width || this.originalWidth; // 默认用设计尺寸
      const realHeight = this.height || this.originalHeight; 
      const widthScale = currentWidth / realWidth;
      const heightScale = currentHeight / realHeight;
      this.dom.style.transform = `scale(${widthScale},${heightScale})`;
    },
    // onresize 事件响应函数
    onResize() {
      this.initSize().then(() => {
        this.updateScale();
      });
    },
    // 初始化事件监听器
    initMutationObserver() {
      // 创建MutationObserver 在DOM发生变化时被调用
      const MutationObserver = window.MutationObserver;
      this.observer = new MutationObserver(this.onResize);
      this.observer.observe(this.dom, {
        attributes: true, // 监听所有节点属性值
        attributeFilter: ['style'], // 指定被监听的属性列表
        attributeOldValue: true, // 记录上一次被监听的节点属性变化
      });
    },
    removeMutationObserver() {
      if (this.observer) {
        this.observer.disconnect(); // 阻止继续接收变化的通知
        this.observer.takeRecords();
        this.observer = null;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.removeMutationObserver();
  },
};
</script>
<style scoped>
#base-container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
</style>
