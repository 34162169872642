<template>
  <div class="home_container">
    <base-container :options="{width:1920,height:1080}" name='base-container' refName='baseContainer'>
      <map-background />
      <div class="home_head_title">
        <img src="../assets/images/home/head.png" alt />
      </div>
      <router-view @mainBtnClose="channelCall" />
      <div class="home_top">
        <div class="weather">
          <div>{{text}}</div>
          <div>{{temp}}℃</div>
          <div>湿度 {{humidity}}</div>
          <div>
            风力等级
            <span>{{windScale}}级</span>
          </div>
        </div>
        <div class="timeShow">
          <span>
            <i class="el-icon-time"></i>
          </span>
          <div class="hms"></div>
          <div class="ymd"></div>
        </div>
        <div class="setting" :class="{ action: showRoute == '/underwater' }" @click="changeSeabedRoute">
          <a>海底视角</a>
        </div>
        <div class="setting">
          <router-link to="/permission" class="el-icon-setting">设置</router-link>
        </div>
        <div class="admin">
          <div class="admin_online">
            admin<i class="el-icon-caret-bottom"></i>
          </div>
          <div class="admin_logout" @click="logOut">退出</div>
        </div>
      </div>
      <div class="btn_router" :class="{ sink_active: !isBtnShow }">
        <span @click="btnFold" :class="{ fold_active: !isBtnShow }"></span>
        <transition name="fade">
          <div class="btn_group" v-show="isBtnShow">
            <router-link to="/bigscreen" :class="[{ click_active: indexSelect === 1 }, 'btn_item']"
              @click.native="homeBtnSwitch(1)">平台首页</router-link>
            <router-link to="/dock" :class="[{ click_active: indexSelect === 2 }, 'btn_item']"
              @click.native="homeBtnSwitch(2)">码头管理</router-link>
            <router-link to="/waterway" :class="[{ click_active: indexSelect === 3 }, 'btn_item']"
              @click.native="homeBtnSwitch(3)">航道监测</router-link>
          </div>
        </transition>
      </div>

      <pop-data-device></pop-data-device>
      <!-- 主航道入口和缺口气泡 -->
      <div v-if="popBreakwaterData.length">
        <div v-for="(item, index) in popBreakwaterData" :key="index">
          <pop-break-water ref="popBreakwaterRef"></pop-break-water>
        </div>
      </div>
    </base-container>

    <!-- 路由占位符 -->
    <!-- 海上航线气泡 -->
    <!-- <div v-if="popRouteData.length">
      <div v-for="(item, index) in popRouteData" :key="index">
        <pop-route ref="popRouteRef" :popData="item" v-show="popRouteData.length"></pop-route>
      </div>
    </div> -->
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import MapBackground from './map'
import popDataDevice from './map/popMarker/pop-data-device.vue'
import popBreakWater from './map/popMarker/pop-break-water.vue'
import baseContainer from '@/components/container/base-container.vue'
import axios from "axios";
import request from "@/utils/request";

// import popRoute from './map/popMarker/pop-route.vue'
export default {
  // 组件名称
  name: 'Home',
  // 局部注册的组件
  components: {
    MapBackground,
    popDataDevice,
    popBreakWater,
    baseContainer
  },
  data () {
    return {
      popRouteData: [], //主航道路线数据
      popBreakwaterData: ['主航道入口气泡', '防波堤缺口气泡'], //航道入口和防波堤数据
      indexSelect: 1,
      isBtnShow: false,
      btnShowBerth: true, //显示泊位标注
      btnShowpopRoute: true, //显示主航道
      showRoute: '/bigscreen', //切换海底视角的路由,
      // 天气预报接口
      text: '晴', // 天气描述
      temp: "24", //体感温度
      windScale: "1", // 风力等级
      humidity: "24", // 湿度
    }
  },
  created () {
    mapApp.homeVueComponent = this
    this.showRoute = this.$route.fullPath
    if (this.showRoute === '/bigscreen') this.indexSelect = 1
    if (this.showRoute === '/dock') this.indexSelect = 2
    if (this.showRoute === '/waterway') this.indexSelect = 3

    // this.getRoutePointsData();// 获取航道点数据
  },
  mounted () {
    this.clock()
    setTimeout(() => {
      this.btnFold()
    }, 500)
    this.getWeatherData()
    this.weatherTimer = setInterval(()=>{
      this.getWeatherData()
    },1000*60*30)
  },
  beforeDestroy () {
    clearInterval(this.timeRunSign)
    clearInterval(this.weatherTimer)
  },
  // 组件方法
  methods: {
    // 底部按钮控制
    homeBtnSwitch (data) {
      this.indexSelect = data
    },
    // 底部折叠
    btnFold (flag) {
      if (mapApp.inSeabedShow == true || flag == false) {
        this.isBtnShow = false
      } else if (flag == true) {
        this.isBtnShow = true
      } else {
        this.isBtnShow = !this.isBtnShow
      }
    },
    // 登出
    logOut () {
      this.$store.commit("logOut")
      const loginPage = location.origin + location.pathname
      location.href = loginPage
    },
    // 时钟
    clock () {
      function timeRun () {
        let time = new Date()
        let year = time.getFullYear()
        let month =
          time.getMonth() + 1 < 10
            ? '0' + (time.getMonth() + 1)
            : time.getMonth() + 1
        let day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
        let hour =
          time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
        let minute =
          time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
        let second =
          time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
        let ymd = year + '-' + month + '-' + day
        let hms = hour + ':' + minute + ':' + second
        document.querySelector('.ymd').innerHTML = ymd
        document.querySelector('.hms').innerHTML = hms
      }
      this.timeRunSign = setInterval(timeRun, 1000) //每一秒执行一次
    },
    // 航道路由通知父组件更改主按钮显隐
    channelCall (val) {
      this.isBtnShow = val
    },
    homeBack () {
      this.$router.push('/bigscreen')
      this.homeBtnSwitch(1)
    },
    // 获取航道点数据
    // async getRoutePointsData() {
    //   try {
    //       const { data } = await request('fn/channelPoint')
    //       console.log("航道点数据",data)
    //       let channelPoint = data.data.channelPoints
    //       for(let key in channelPoint){
    //         this.popRouteData.push({
    //           id: key,
    //           name: "1号航道",
    //           msgData: {
    //             markerName: "记录点"+key.substring(key.length-1, key.length),
    //             direction: channelPoint[key].direction, //流向
    //             tidalLevel: channelPoint[key].tidalLevel, // 潮位
    //             ukc: channelPoint[key].ukc, // 富余水深
    //             velocity: channelPoint[key].velocity.toFixed(3)*1, // 流速
    //             waterDepth: channelPoint[key].waterDepth, // 水深【暂不显示】
    //           }
    //         })
    //       }
    //       if(this.popRouteData.length == 0) {
    //         console.error('获取航道点数据失败!');
    //       }
    //   } catch (err) {
    //     console.log('获取数据失败！', err)
    //   }
    // },
    //设置主航道路线
    // getPopRouteBinding(){
    //   if(!this.$refs.popRouteRef) return
    //   for (let i = 0; i < this.$refs.popRouteRef.length; i++) {
    //     mapApp.channelMapActions.routePopVueComponents[i] = this.$refs.popRouteRef[i]
    //   }
    // },
    //设置主航道入口和防波堤气泡
    getPopBreakwater () {
      if (!this.$refs.popBreakwaterRef) return
      for (let i = 0; i < this.$refs.popBreakwaterRef.length; i++) {
        mapApp.boatsMapActions.breakwaterPopVueComponent[i] =
          this.$refs.popBreakwaterRef[i]
      }
    },
    // 改变海底视角按钮的路由
    changeSeabedRoute () {
      if (this.showRoute == '/underwater' && mapApp.inSeabedShow) {
        this.showRoute = '/bigscreen'
        this.homeBtnSwitch(1)
      } else {
        this.showRoute = '/underwater'
      }
      this.$router.push(this.showRoute)
    },
    // 多分辨率显示器适配
    handleScreenAuto () {
      const designDraftWidth = 1920;//设计稿的宽度
      const designDraftHeight = 1080;//设计稿的高度
      const currentWidth = document.documentElement.clientWidth // 当前浏览器宽度
      const currentHeight = document.documentElement.clientHeight // 当前浏览器高度
      if( currentWidth >= 1920 ) return

      // 平移比例
      const rate = currentWidth <= 1920 ? '-80%' : '-50%'
      //宽高相同缩放比例，选择较小的
      const scale = currentWidth / currentHeight < designDraftWidth / designDraftHeight ?
          (currentWidth / designDraftWidth) :
          (currentHeight / designDraftHeight);
      (document.querySelector('.btn_router')).style.transform = `scale(${scale}) translate(${rate})`;

      // 宽高不同缩放比例
      // const widthScale =  currentWidth / designDraftWidth;
      // const heightScale = currentHeight / designDraftHeight;
      // // console.log(widthScale,heightScale)
      // (document.querySelector('#btn_router')).style.transform = `scale(${widthScale},${heightScale})`;
    },
    // 获取天气API数据,天气数据不靠谱，暂时用不到
    async getWeatherData(){
      try {
        const { data } = await axios.get('https://devapi.qweather.com/v7/weather/now', {
          // 传递的参数
          params: {
             location:"117.20,39.084", // 位置
             key:"e5034cbd39754aa0b47dbc030506d87d", // key
          }
        })
        const res = await request('fn/wind',1)
        let windList = res.data.data.wind
        this.windScale = windList[5].INST_SPEED ? this.windSpeedToLevel(windList[5].INST_SPEED) : ' '
        this.text = data.now.text // 天气描述
        this.temp = data.now.temp // 体感温度
        this.humidity = data.now.humidity // 相对湿度
      } catch (err) {
        console.log("天气数据获取失败",err)
      }
    },
    // 风速换算为等级
    windSpeedToLevel(windSpeed) {
      let windLevel;
      switch (true) {
        case (windSpeed >= 0 && windSpeed <= 0.2):
          windLevel = 0;
          break;
        case (windSpeed > 0.2 && windSpeed <= 1.5):
          windLevel = 1;
          break;
        case (windSpeed > 1.5 && windSpeed <= 3.3):
          windLevel = 2;
          break;
        case (windSpeed > 3.3 && windSpeed <= 5.4):
          windLevel = 3;
          break;
        case (windSpeed > 5.4 && windSpeed <= 7.9):
          windLevel = 4;
          break;
        case (windSpeed > 7.9 && windSpeed <= 10.7):
          windLevel = 5;
          break;
        case (windSpeed > 10.7 && windSpeed <= 13.8):
          windLevel = 6;
          break;
        case (windSpeed > 13.8 && windSpeed <= 17.1):
          windLevel = 7;
          break;
        case (windSpeed > 17.1 && windSpeed <= 20.7):
          windLevel = 8;
          break;
        case (windSpeed > 20.7 && windSpeed <= 24.4):
          windLevel = 9;
          break;
        case (windSpeed > 24.4 && windSpeed <= 28.4):
          windLevel = 10;
          break;
        case (windSpeed > 28.4 && windSpeed <= 32.6):
          windLevel = 11;
          break;
        default:
          windLevel = 12;
          break;
      }
    return windLevel;
    },
  }
}
</script>

<style lang="scss" scoped>
.home_container {
  height: 100%;
  font-family: WenQuanDengKuanWeiMiHei;
}

.home_head_title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
  width: 100%;
  min-width: 1080;
  background-image: linear-gradient(to bottom,
      rgb(3, 12, 30),
      rgb(3, 10, 24, 0.6) 70%,
      rgba(0, 0, 0, 0));
  text-align: center;
  pointer-events: none;

  img {
    width: 1460px;
    height: 88px;
  }
}

.home_top {
  position: absolute;
  top: 40px;
  right: 1%;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  color: rgba(255, 255, 255, 0.712);
  z-index: 10;

  .weather {
    display: flex;
    margin-right: 10px;

    div {
      margin-right: 10px;
    }
  }

  .timeShow {
    display: flex;
    margin-right: 10px;

    span {
      line-height: 19px;
      padding-right: 5px;
    }

    .hms {
      margin-right: 10px;
    }
  }

  .setting {
    margin-right: 10px;
    padding: 1px 6px;
    border: 1px solid #4cbffd71;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
    cursor: pointer;
    color: #fff;
  }

  .admin {
    position: relative;

    &:hover .admin_logout {
      display: block;
    }

    .admin_online {
      padding: 0 4px;
      border: 1px solid #4cbffd71;
      font-size: 12px;
      line-height: 18px;
      box-sizing: border-box;
      cursor: pointer;

      i {
        margin-left: 2px;
      }
    }

    .admin_logout {
      position: absolute;
      display: none;
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.712);
      background-color: #374153;
      cursor: pointer;
    }
  }
}

.btn_router {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 96px;
  width: 1121px;
  box-sizing: border-box;
  padding: 36px 360px;
  background-image: url('../assets/images/home/btn_bg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  transition: all 1s;
  pointer-events: none;

  span {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 18px;
    background-image: url('../assets/images/home/btn_arrow.png');
    cursor: pointer;
    transition: all 0.5s;
    pointer-events: auto;
  }

  .fold_active {
    transform: rotateX(180deg) translateX(-50%);
  }

  .btn_group {
    display: flex;
    justify-content: space-around;

    .btn_item {
      width: 96px;
      height: 41px;
      background-image: url('../assets/images/home/router_btn.png');
      text-align: center;
      line-height: 42px;
      color: rgb(231, 240, 255);
      font-size: 18px;
      pointer-events: auto;
    }

    .click_active {
      background-image: url('../assets/images/home/router_btn_clicked.png');
      color: rgb(88, 249, 255);
    }
  }
}



.sink_active {
  bottom: -55px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.action {
  background-color: #0f3d998f;
}
</style>
