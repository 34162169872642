<template>
  <div class="pop_container" :style="{ transform: popTips.transformStyle }" v-show="popTips.visible">
    <div class="box">
      <div class="pop_title">
        <div class="title_left">
          <!-- <img src="../../../assets/images/channel/定位.png" /> -->
          <img src="../../../assets/images/overview/潮流.png" v-show="dataDeviceType == DEVICE_TYPE.SEA_TIDE"/>
          <img src="../../../assets/images/overview/潮位.png" v-show="dataDeviceType == DEVICE_TYPE.SEA_LEVEL"/>
          <img src="../../../assets/images/overview/风场.png" v-show="dataDeviceType == DEVICE_TYPE.WEATHER"/>
          <div>
            观测站：<span>{{ dataDeviceType }}</span>
          </div>
        </div>
        <div class="close" @click="popClose"><i class="el-icon-close"></i></div>
      </div>

      <div class="content" v-show="dataDeviceType == DEVICE_TYPE.SEA_LEVEL">
        <div class="pop_right">
          <div class="pop_detail_row">
            <div class="item">实时潮高：<span>{{ seaLevelData.realSeaLevel[0] }}m</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">预测潮高：<span>{{ seaLevelData.forcastSeaLevel[0] }}m</span></div>
          </div>
          <div class="pop_detail_row2">
            <div class="item">经度：<span>{{ seaLevelData.lon }}</span></div>
            <div class="item">纬度：<span>{{ seaLevelData.lat }}</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">时间戳：<span>{{ seaLevelData.timeStr }}</span></div>
          </div>
          <div class="pop_more"><i class="el-icon-caret-bottom"></i><span>查看更多</span></div>
        </div>
      </div>
      <div class="content" v-show="dataDeviceType == DEVICE_TYPE.SEA_TIDE">
        <div class="pop_right">
          <div class="pop_detail_row">
            <div class="item">实时潮高：<span>{{ seaLevelData.realSeaLevel[1] }}m</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">预测潮高：<span>{{ seaLevelData.forcastSeaLevel[1] }}m</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">流速：<span>{{ seaTideData.flowSpeed }}m/s</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">流向：<span>{{ seaTideData.flowDirect || 0 }}°</span></div>
          </div>
          <div class="pop_detail_row2">
            <div class="item">经度：<span>{{ seaTideData.lon }}</span></div>
            <div class="item">纬度：<span>{{ seaTideData.lon }}</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">时间戳：<span>{{ seaTideData.timeStr }}</span></div>
          </div>
          <div class="pop_more"><i class="el-icon-caret-bottom"></i><span>查看更多</span></div>
        </div>
      </div>
      <div class="content" v-show="dataDeviceType == DEVICE_TYPE.WEATHER">
        <div class="pop_right">
          <div class="pop_detail_row">
            <div class="item">风速</div>
          </div>
          <div class="pop_detail_row2">
            <!-- <div class="item">及风向：<span>{{ weatherData.instantWindSD }}</span></div> -->
            <div class="item">瞬时风速：<span>{{ weatherData.instantWindSD[0] }}m/s</span></div>
            <div class="item">风向：<span>{{ weatherData.instantWindSD[1] }}</span><span v-show="weatherData.instantWindSD[1] && weatherData.instantWindSD[1] > 0">°</span></div>
          </div>
          <div class="pop_detail_row2">
            <!-- <div class="item">及风向：<span>{{ weatherData.twoMinWindSD }}</span></div> -->
            <div class="item">两分钟平均风速：<span>{{ weatherData.twoMinWindSD[0] }}m/s</span></div>
            <div class="item">风向：<span>{{ weatherData.twoMinWindSD[1] }}</span><span v-show="weatherData.instantWindSD[1] && weatherData.twoMinWindSD[1] > 0">°</span></div>
          </div>
          <div class="pop_detail_row2">
            <!-- <div class="item">十分钟平均风速及风向：<span>{{ weatherData.tenMinWindSD }}</span></div> -->
            <div class="item">十分钟平均风速：<span>{{ weatherData.tenMinWindSD[0] }}m/s</span></div>
            <div class="item">风向：<span>{{ weatherData.tenMinWindSD[1] }}</span><span v-show="weatherData.instantWindSD[1] && weatherData.tenMinWindSD[1] > 0">°</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">能见度</div>
          </div>
          <div class="pop_detail_row">
            <div class="item">十分钟平均值：<span>{{ weatherData.averageVisibility }}km</span></div>
          </div>
          <div class="pop_detail_row2">
            <div class="item">经度：<span>{{ weatherData.lon }}</span></div>
            <div class="item">纬度：<span>{{ weatherData.lat }}</span></div>
          </div>
          <div class="pop_detail_row">
            <div class="item">时间戳：<span>{{ weatherData.timeStr }}</span></div>
          </div>
          <div class="pop_more"><i class="el-icon-caret-bottom"></i><span>查看更多</span></div>
        </div>
      </div>
      
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
export default {
  name: 'DataDevicePop',
  data() {
    return {
      DEVICE_TYPE: {
        WEATHER: "气象站",
        SEA_LEVEL: "岸基潮位站",
        SEA_TIDE: "海上潮流站"
      },
      popTips: {
        x: null,
        y: null,
        floorNum: 1,
        offsetHeight: 0,
        transformStyle: '',
        visible: false
      },
      dataDeviceType: '',
      seaLevelData: {
        realSeaLevel: [0, 0],
        forcastSeaLevel: [0, 0],
        lon: '',
        lat: '',
        timeStr: ''
      },
      seaTideData: {
        flowSpeed: '',
        flowDirect: 0,
        lon: '',
        lat: '',
        timeStr: ''
      },
      weatherData: {
        instantWindSD: [0, 0],
        twoMinWindSD: [0, 0],
        tenMinWindSD: [0, 0],
        averageVisibility: '',
        lon: '',
        lat: '',
        timeStr: ''
      },
      // longitude: '',
      // latitude: ''
    }
  },
  mounted() {
    mapApp.dataDevicePopVueComponent = this
    console.log('加载完成')
  },
  destroyed() {
    mapApp.dataDevicePopVueComponent = null
  },
  // 组件方法
  methods: {
    setData(data) {
      // console.log(mapApp.dataDevicePopData);
      if(data.name == this.DEVICE_TYPE.SEA_LEVEL) {
        mapApp.dataDevicePopData.seaLevelData.lon = data.msgData.lon;
        mapApp.dataDevicePopData.seaLevelData.lat = data.msgData.lat;
        this.seaLevelData.realSeaLevel = mapApp.dataDevicePopData.seaLevelData.realSeaLevel;
        this.seaLevelData.forcastSeaLevel = mapApp.dataDevicePopData.seaLevelData.forcastSeaLevel;
        this.seaLevelData.lon = mapApp.dataDevicePopData.seaLevelData.lon;
        this.seaLevelData.lat = mapApp.dataDevicePopData.seaLevelData.lat;
        this.seaLevelData.timeStr = mapApp.dataDevicePopData.seaLevelData.timeStr;
      }
      if(data.name == this.DEVICE_TYPE.SEA_TIDE) {
        mapApp.dataDevicePopData.seaTideData.lon = data.msgData.lon;
        mapApp.dataDevicePopData.seaTideData.lat = data.msgData.lat;
        this.seaTideData.flowSpeed = mapApp.dataDevicePopData.seaTideData.flowSpeed;
        this.seaTideData.flowDirect = mapApp.dataDevicePopData.seaTideData.flowDirect;
        this.seaTideData.lon = mapApp.dataDevicePopData.seaTideData.lon;
        this.seaTideData.lat = mapApp.dataDevicePopData.seaTideData.lat;
        const timeStr = mapApp.dataDevicePopData.seaTideData.timeStr;
        this.seaTideData.timeStr = timeStr.slice(0,-5).split('T').join('  ')
        
      }
      if(data.name == this.DEVICE_TYPE.WEATHER) {
        mapApp.dataDevicePopData.weatherData.lon = data.msgData.lon;
        mapApp.dataDevicePopData.weatherData.lat = data.msgData.lat;
        this.weatherData.instantWindSD = mapApp.dataDevicePopData.weatherData.instantWindSD;
        this.weatherData.twoMinWindSD = mapApp.dataDevicePopData.weatherData.twoMinWindSD;
        this.weatherData.tenMinWindSD = mapApp.dataDevicePopData.weatherData.tenMinWindSD;
        this.weatherData.averageVisibility = mapApp.dataDevicePopData.weatherData.averageVisibility;
        this.weatherData.lon = mapApp.dataDevicePopData.weatherData.lon;
        this.weatherData.lat = mapApp.dataDevicePopData.weatherData.lat;
        this.weatherData.timeStr = mapApp.dataDevicePopData.weatherData.timeStr;
      }
    },
    popClose() {
      mapApp.closeDataDevicePop()
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_container {
  position: absolute;
  pointer-events: none;
  // animation: shutter-in-bottom 0.5s;
  .box {
    position: relative;
    top: -10px;
    // transform: translateX(50%);
    // width: 200px;
    padding: 10px 15px;
    box-sizing: border-box;
    border: 1px solid #1c899c;
    background-color: #03050eb0;
    color: #fff;
    font-size: 12px;
    // font-weight: bolder;
    white-space: nowrap;
    font-family: SiYuan;
    // animation: shutter-in-bottom 0.5s ease forwards;
    .content {
      display: flex;
      justify-content: space-between;
      .pop_icon {
        padding-left: 15px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 130px;
        .outer {
          position: relative;
          width: 72px;
          height: 72px;
          background-image: url('../../../assets/images/channel/circle.png');
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .point {
            width: 62px;
            height: 62px;
            background-image: url('../../../assets/images/channel/circle_point.png');
            background-size: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 36px;
              height: 38px;
              margin-bottom: 4px;
            }
          }
          .inside {
            position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            width: 72px;
            height: 72px;
            background-image: url('../../../assets/images/channel/circle1.png');
            background-size: 100%;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            animation: circle_rotate 5s infinite;
            animation-timing-function: linear;
          }
        }
        .pop_btn {
          margin-top: 5px;
          div {
            height: 20px;
            width: 50px;
            line-height: 20px;
            padding: 0 5px;
            background-color: #275185;
            margin-top: 5px;
            text-align: center;
            pointer-events: auto;
            cursor: pointer;
          }
          .active {
            background-color: #0073ff;
          }
        }
      }
      .pop_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0px;
      }

      .pop_detail {
        margin: 10px 0 10px 10px;
        display: grid;
        grid-template-columns: 50% 50%;
        .item {
          margin: 0 10px 20px 5px;
          span {
            font-size: 14px;
            color: #04d9ff;
          }
        }
        .pop_dynamic {
          display: grid;
          grid-template-columns: 50% 50%;
        }
      }

      .pop_detail_row {
        // margin: 5px 0 5px 5px;
        display: grid;
        grid-template-columns: 100% 100%;
        .item {
          // margin: 0 10px 20px 5px;
          margin: 0 5px 5px 5px;
          span {
            font-size: 14px;
            color: #04d9ff;
          }
        }
      }
      .pop_detail_row_2c {
        display: grid;
        grid-template-columns: 50% 50%;
      }

      .pop_detail_row2 {
        margin: 5px 0 5px 0px;
        .item {
          float: left;
          // margin: 0 10px 20px 5px;
          margin: 0 5px 5px 5px;
          span {
            font-size: 14px;
            color: #04d9ff;
          }
        }
      }

      .pop_more {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        border-bottom: 20px solid #275185;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        height: 0;
        width: 70px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        i {
          padding-right: 2px;
        }
        span {
          font-size: 12px;
        }
      }
    }

    .pop_title {
      color: #fff;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-image: linear-gradient(to right, #173d66, rgba(0, 0, 0, 0));
      padding: 5px;
      line-height: 24px;
      vertical-align: middle;
      font-size: 16px;
      font-family: YouSheBiaoTiHei;
      .title_left {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        div {
          margin-left: 5px;
        }
      }
      .close {
        width: 18px;
        height: 18px;
        color: #27abc2;
        margin-right: 10px;
        margin-top: 2px;
        box-sizing: border-box;
        border: 2px solid #1e92a7;
        text-align: center;
        line-height: 12px;
        cursor: pointer;
        pointer-events: auto;
        i {
          font-size: 12px;
          font-weight: bolder;
        }
      }
    }
  }
  .line {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    width: 2px;
    height: 40px;
    background-color: #25bcd6;
  }
}
@keyframes shutter-in-bottom {
  0% {
    transform: rotateY(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
</style>
