<template>
  <div class="map_container">
    <div class="clickShade" v-show="clickShadeSign"></div>
    <!-- loading -->
    <LoadingTriangle :show="loadingSign" />
    <!-- <LoadingCircleRotate :show="loadingSign" /> -->
    <div id="map-container"></div>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import LoadingTriangle from '@/components/loading-triangle.vue'
// import LoadingCircleRotate from '../../components/loading-circle-rotate.vue'

export default {
  name: 'MapIndex',
  components: { LoadingTriangle },
  data() {
    return {
      clickShadeSign: false,
      loadingSign: true
    }
  },
  mounted() {
    console.log(this.$store.state.loginSign)
    // 初始化地图
    mapApp.initMap({ loginSign: this.$store.state.loginSign })
    window.mapApp = mapApp
    this.getClickShadeBinding()
  },
  beforeDestroy() {},
  methods: {
    getClickShadeBinding() {
      mapApp.mapContainer = this
    }
  }
}
</script>

<style lang="scss">
.map_container {
  .clickShade {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: wait;
  }
  #map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
  }
  .default_box {
    box-sizing: border-box;
    font-size: 20px;
    text-align: center;
    padding: 5px 15px;
    letter-spacing: 2px;
    color: #fff;
    // border: 1px solid #fff;
    border-radius: 2px;
    box-shadow: 0 1px #fff;
    // background-color: #5e96e4;
    background-image: linear-gradient(to bottom, #27a8ff, #4dd1d1);
    font-family: SiYuan;
  }
  .default_arrow {
    position: relative;
    top: 8px;
    width: 100%;
    height: 20px;
    background-image: url('../../assets/images/map/arrow.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 36px 20px;
    animation-name: bounce-bottom;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}
@keyframes bounce-bottom {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(8px);
  }
}
</style>
